body {
  background-color: #F3F4F9;
}

.navtop {
  margin-bottom: 75px;
 }
 
 .formstyle {
  box-shadow: 0px 5px 25px #AAB7F024;
  border-radius: 10px 10px 0px 0px;
  background-color: #FFFFFF;
  max-width: 430px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
 
 }
 
 /* .imgstyle {
 
  height: 100%;
 
 } */
 /* .ant-picker-header > button.ant-picker-header-super-prev-btn,button.ant-picker-header-prev-btn,button.ant-picker-header-next-btn,
button.ant-picker-header-super-next-btn,
button.ant-picker-year-btn {
      display: none !important;
} */
 
 .h3,
 h3 {
  font-size: 22px !important;
 }
 
 .g-5,
 .gx-5 {
  --bs-gutter-x: 5rem !important;
 }
 
 .sub_btn {
  background-color: #252E65;
  height: 40px;
  font: normal normal normal 11px Poppins;
  color: #FFFFFF;
 }
 
 .formstyle {
  color: #33373D;
  height: 100%;
 }

 .faqstyle {
  box-shadow: 0px 5px 25px #AAB7F024;
  border-radius: 10px 10px 0px 0px;
  background-color: #FFFFFF;
  max-width: 430px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
  color: #33373D;
 }
 
 .contactus {
  min-height: 100%;
 }
 
 .formstyle input {
  height: 100%;
 }
 
 label {
  font: normal normal medium 12px Inter;
  color: #5A6073;
 }
 
 .form-label {
  margin-bottom: .5rem;
  font-size: 12px;
 }
 
 
 .contactno {
  color: #5A6073;
 }
 
 .contactno p {
  font-size: 14px;
  color: #3A4065;
 }
 
 .h3style {
  font: normal normal medium 16px Inter;
  color: #33373D;
  line-height: 29px;
 }
 
 .footerstyle {
  background-color: #4C5275;
  width: 100%;
 }
 
 .footerstyle p {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 300;
  opacity: 70%;
 }
 
 .terms,
 .contactno_style {
  font-size: 12px;
 }
 
 @media (min-width:1240px) {
 
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1194px;
  }
 
 }
 
 @media only screen and (max-width: 992px) {
  .formstyle {
    margin: 0 auto;
 
  }
 
  .imgstyle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: 100%;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 992px) {
    .imgstyle {
      display: none;
    }  
  }
  .navtop {
    margin-bottom: 30px;
  }
 
  .footerstyle {
    margin-top: 30px;
  }
 }
 .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}
 .error__page__wrapper .page__inside{display:flex;justify-content:center;align-items:center;min-height:100vh;max-width:1200px;padding:0 20px;margin:auto}.error__page__wrapper .page__inside img{max-width:600px}.error__page__wrapper h1{font-size:50px;font-weight:700}.error__page__wrapper p{font-size:18px;line-height:32px;line-height:24px}.error__page__wrapper .link__goback{margin-top:20px}
 
 .ant-picker-header > button.ant-picker-header-super-prev-btn,
  button.ant-picker-header-prev-btn,button.ant-picker-header-next-btn,
   button.ant-picker-header-super-next-btn,.ant-picker-footer,
   button.ant-picker-year-btn {
      display: none!important;
   }
 @media only screen and (max-width:578px) {
  .terms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }
 
 }